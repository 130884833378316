import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApplicationConfig, inject } from '@angular/core';
import { ApolloClientOptions, InMemoryCache, NormalizedCacheObject } from '@apollo/client/core';
import { environment } from '@environments/environment';
import { captureException, captureMessage } from '@sentry/angular';
import { onError } from '@apollo/client/link/error';

export function apolloOptionsFactory(): ApolloClientOptions<NormalizedCacheObject> {
  const httpLink = inject(HttpLink);
  const errorHandlerLink = onError((err, ...param) => {
    const { graphQLErrors, networkError } = err;
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) => {
        captureMessage(message);
      });
    }
    if (networkError) {
      captureException(networkError);
    }
  });
  return {
    link: errorHandlerLink.concat(httpLink.create({ uri: environment.apiUrl })),
    cache: new InMemoryCache({}),
  };
}

export const graphqlProvider: ApplicationConfig['providers'] = [
  Apollo,
  {
    provide: APOLLO_OPTIONS,
    useFactory: apolloOptionsFactory,
  },
];
