import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TokenService } from '@core/services/token.service';
import { UserRightsService } from '@core/services/user-rights.service';
import { filter, mergeMap, Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AdminDataState } from '@core/store/state/admin-data.state';
import { SpinnerComponent } from './modules/spinner/spinner.component';
import { ToastComponent } from './modules/toasts/toast-component/toast.component';

@UntilDestroy()
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, SpinnerComponent, ToastComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  public title = 'dms-front';

  private accessToken$: Observable<string | null> = inject(Store).select(AdminDataState.getAccessToken);

  constructor(
    private readonly tokenService: TokenService,
    private userRightsService: UserRightsService,
  ) {}

  public ngOnInit(): void {
    this.getUserRights();
  }

  private getUserRights(): void {
    this.accessToken$
      .pipe(
        untilDestroyed(this),
        filter((accessToken) => !!accessToken),
        mergeMap(() => this.userRightsService.getUserRights()),
      )
      .subscribe();
  }
}
