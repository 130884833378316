import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  LOCALE_ID,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter, Router } from '@angular/router';
import localeUk from '@angular/common/locales/uk';
import localeEn from '@angular/common/locales/en';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { NgxsModule } from '@ngxs/store';
import {
  HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, HttpClient,
} from '@angular/common/http';
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { AuthInterceptor } from '@core/interceptors/auth-interceptor';
import { LoaderInterceptor } from '@core/interceptors/loader-interceptor';
import * as Sentry from '@sentry/angular';
import { environment } from '@environments/environment';
import { ZonesState } from '@core/store/state/zone.state';
import { VehiclesState } from '@core/store/state/vehicle.state';
import { ReferencesState } from '@core/store/state/reference.state';
import { TimeWindowState } from '@core/store/state/time-window.state';
import { PharmaciesState } from '@core/store/state/pharmacy.state';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { LocaleService } from '@core/services/locale.service';
import { registerLocaleData } from '@angular/common';
import { ErrorInterceptor } from '@core/interceptors/error-interceptor';
import { RequestTimeWindowState } from '@core/store/state/requests-time-windows.state';
import { CourierSchedulesState } from '@core/store/state/courier-work-schedule.state';
import { CouriersState } from '@core/store/state/courier.state';
import { AdminDataState } from '@core/store/state/admin-data.state';
import { OrderReferenceGuideState } from '@core/store/state/order-reference-guide.state';
import { DEFAULT_LOCALE, LOCALE_STORAGE_KEY } from '@core/constants/admin.constants';
import { UserRightsState } from '@core/store/state/user-rights-state';
import { routes } from './app.routes';
import { graphqlProvider } from './graphql.provider';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

export function initializeApp(translateService: TranslateService): () => void {
  return () => {
    const locale = localStorage.getItem(LOCALE_STORAGE_KEY) || DEFAULT_LOCALE;
    return firstValueFrom(translateService.use(locale!));
  };
}

registerLocaleData(localeUk, 'uk');
registerLocaleData(localeEn, 'en');

export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  const baseUrl = 'https://dms-demo-api.dev2.scrij.com/public/dms/translations';
  return new TranslateHttpLoader(http, `${baseUrl}/`, '?updatedAfter=2024-12-20');
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync('animations'),
    provideEnvironmentNgxMask(maskConfig),
    provideHttpClient(),

    importProvidersFrom(
      NgxsModule.forRoot(
        [
          ZonesState,
          CouriersState,
          PharmaciesState,
          TimeWindowState,
          OrderReferenceGuideState,
          VehiclesState,
          AdminDataState,
          ReferencesState,
          CourierSchedulesState,
          RequestTimeWindowState,
          UserRightsState,
        ],
        {
          developmentMode: !environment.production,
        },
      ),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ),
    graphqlProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [TranslateService, LocaleService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'uk' },
  ],
};
