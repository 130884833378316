<div class="wrapper">
  <div class="container">
    <div class="header">
      <img src="/assets/icons/shared-icons/logo.svg" alt="logo">
    </div>

    <div class="auth-wrapper">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmitForm()">
        <p class="title">{{('forgot_password_header' | translate )}}</p>
        <div class="auth-container">
          <div class="number-container">
            <p class="input-info">{{('forgot_password_header' | translate  )}}</p>
            <mat-form-field class="number-form-field">
              <input matInput formControlName="phoneNumber" [prefix]="phoneCountryCode" mask="(000) 000 00 00" class="login-input" type="text" placeholder="+38(000) 000 00 00">
            </mat-form-field>
          </div>
          @if(loginForm.get('phoneNumber')?.hasError('invalidPhoneNumber') && loginForm.get('phoneNumber')?.dirty){
            <mat-error>
              Invalid phone number
            </mat-error>
            }
            @if(timerValue !== null && timerValue >= 1){
              <div class="timer">
                Ви можете повторно надіслати через {{ timerValue }} секунд.
              </div>
            }
        </div>
        @if(errorMessage){
          <mat-error>
            {{errorMessage}}
          </mat-error>
          }
      <button [disabled]="!loginForm.valid || isButtonDisabled" class="submit" mat-button >{{'forgot_password_send_code' | translate }}</button>
    </form>
    <a [routerLink]="'/auth'" class="back">
      {{'forgot_password_title_return' | translate}}
    </a>
    </div>
  </div>
</div>
