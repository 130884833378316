import { Component, OnInit } from '@angular/core';
import {
  FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterModule } from '@angular/router';
import { NgxMaskDirective } from 'ngx-mask';
import { ApiAuthService } from '@core/services/api-auth.service';
import { ForgotPasswordResponse } from '@core/interfaces/auth.interface';
import { MutationResult } from 'apollo-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastService } from '@core/services/tost.service';
import { ToastType } from '@core/enums/toast-type.enum';
import { PHONE_COUNTRY_CODE } from '@core/constants/admin.constants';
import { phoneNumberValidator } from '../phone-number-validator';
import { PasswordVisibilityDirective } from '../password-visibility.directive';

@UntilDestroy()
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordVisibilityDirective,
    RouterModule,
    NgxMaskDirective,
    TranslateModule,
  ],
})
export class ForgotPasswordComponent implements OnInit {
  public loginForm!: FormGroup;

  public errorMessage: string = '';

  public timerValue: number | null = null;

  public timer$: Subject<void> = new Subject<void>();

  public isButtonDisabled: boolean = false;

  public readonly phoneCountryCode = PHONE_COUNTRY_CODE;

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly apiAuthService: ApiAuthService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.createForm();
  }

  private ngAfterContentChecked(): void {
    this.getTimeForSecondSend();
  }

  private getTimeForSecondSend(): void {
    this.apiAuthService
      .getTimerValue()
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.timerValue = value;
        this.isButtonDisabled = value !== null && value > 0;
      });
  }

  public createForm(): void {
    this.loginForm = this.fb.group({
      phoneNumber: new FormControl('', [Validators.required, phoneNumberValidator()]),
    });
  }

  public onSubmitForm(): void {
    const { phoneNumber } = this.loginForm.value;
    const phoneNumberString = `38${phoneNumber}`;

    this.apiAuthService
      .forgotPassword(phoneNumberString)
      .pipe(untilDestroyed(this))
      .subscribe(
        (res: MutationResult<{ forgotPassword: ForgotPasswordResponse }>) => {
          if (res.data && res.data.forgotPassword.success) {
            const formattedPhoneNumber = `${PHONE_COUNTRY_CODE} (${phoneNumber.substring(0, 3)}) *** ** ${phoneNumber.substring(8)}`;
            const toastMessage = `${this.translateService.instant('we_have_sent_temporary_password_on_number')} ${formattedPhoneNumber}`;
            this.toastService.showToast(toastMessage, ToastType.success);
            void this.router.navigateByUrl('auth');
            this.apiAuthService.startTimer();
          }
        },
        () => {
          this.errorMessage = 'Incorrect';
          this.loginForm.get('phoneNumber')!.setErrors({ incorrect: true });
        },
      );
  }
}
